import { memo } from 'react';
import { connect } from 'react-redux';

import { IAppState } from 'shared/common/state';
import { ICollectionItem } from 'shared/offer';

import { Slider } from '../Slider';

import { Collection } from './components/Collection';
import { trackClickCollection, trackSwipeCollection } from './tracking';

import * as styles from './Collections.css';

const SLIDER_CLASSES = {
  group: styles['slider-group'],
  groupsContainer: styles['slider-groups-container'],
};

interface IProps {
  collectionItems: ICollectionItem[];
  cdnBaseUrl: string;
}

export const CollectionsComponent = ({ collectionItems, cdnBaseUrl }: IProps) => {
  const createClickHandler = (queryString: string) => () => {
    trackClickCollection(queryString);
  };

  if (collectionItems.length === 0) {
    return null;
  }

  return (
    <div className={styles['wrapper']}>
      <Slider
        classes={SLIDER_CLASSES}
        step={4}
        visibleSlidesCount={4}
        onNextButtonClick={trackSwipeCollection}
        onPrevButtonClick={trackSwipeCollection}
      >
        {collectionItems.map(({ key, title, url, label, queryString, textColor, backgroundColor }) => (
          <Collection
            backgroundColor={backgroundColor}
            cdnBaseUrl={cdnBaseUrl}
            className={styles['desktop-card']}
            color={textColor}
            href={url}
            imageKey={key}
            key={title}
            label={label}
            title={title}
            onClick={createClickHandler(queryString)}
          />
        ))}
      </Slider>
    </div>
  );
};

export const mapStateToProps = (state: IAppState): IProps => {
  return {
    collectionItems: state.collections || [],
    cdnBaseUrl:
      state.config.get<string>('collectionsCdn.baseUrl') || '//static.cdn-cian.ru/frontend/collections-assets/',
  };
};

export const Collections = connect(mapStateToProps)(memo(CollectionsComponent));
